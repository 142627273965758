.dark_mode {
    background-color: black;
    color: white;
}

.light_mode {
    background-color: white;
    color: black;
}

.divisioncomp {
    display: flex;
}
.user-table-container{
    flex: 1;
}

.user-table {
    margin-top: 20px;
    flex: 1;
}

.user-table table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

.user-table th,
.user-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    text-align: center;
}

.user-table th {
    background-color: #f2f2f2;
}

.recharge-btn {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    position: relative;
}

.recharge-btn:hover+.tooltip {
    visibility: visible;
    /* top: -1px; */
}

.tooltip {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 4px;
        padding: 3px 6px;
    position: absolute;
    z-index: 1;
    /* top: -2px;    */
    /* left: 50%; */
    /* top: 2%; */
    transform: translateX(-50%);
}

.tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {  
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

}

.popup-content h2 {
    margin-top: 0;
    margin-bottom: 20px;
  
}

.popup-content h5{
    margin-top: 20px;
        margin-bottom: 2px;
}

.popup-content p {
    margin-bottom: 10px;

}

.popup-content label {
    display: block;
    margin-bottom: 5px;

}

.popup-content input {
    margin-bottom: 10px;
}

.popup-content button {
    margin-right: 10px;
    margin-left: 10px;
   
        background-color: #007bff;
        /* Default button background color */
        color: white;
        border: none;
        border-radius: 4px;
        padding: 8px 16px;
        cursor: pointer;
        transition: background-color 0.3s;
}
.popup-content button:hover {
    background-color: #28a745;

}

.popup-content button.cancel-btn:hover {
    background-color: #dc3545;

}

.popup-content p {
    margin: 5px 0;
}

.wallet-recharge-request {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-right: 25px;
    
}

.wallet-recharge-request button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    letter-spacing: 1;
}

.wallet-recharge-request button:hover {
    background-color: #db333b;
}

.popup-content {
    max-width: 400px;
   
}

.request-header {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
}

/* .request-close-btn {
    padding: 8px 16px;
    margin-top: 20px;
        margin-bottom: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    letter-spacing: 1px;
}

.request-close-btn:hover {
    background-color: #db333b;
} */

.request-close-btn {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    letter-spacing: 1px;
    display: inline-block;
    text-align: center;
}

.request-close-btn:hover {
    background-color: #db333b;
}

.transaction-card {
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 16px;
    background-color: #f9f9f9;
}

.transaction-card h3 {
    margin-bottom: 8px;
 
}

.transaction-card p {
    margin: 4px 0;
}

.transaction-card button.recharge-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    bottom: 30px;
    left: 15px;
}

.transaction-card button.recharge-btn:hover {
    background-color: #28a745;
}