@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
}


/* Table header section */

.Head_screen {
    display: flex;
    justify-content: space-between;
    gap: 70px;
    height: 64px;
    width: 100%;
}

.Head_screen_button {
    background-color: #DEDEDE;
    width: 404px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 42px;
    padding: 10px;

}

.shares {
    background-color: #000000;
    padding: 10px 25px;
    border-radius: 54px;
    color: white;
    border: none;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    cursor: pointer;

}

.Commodities {
    padding: 10px 25px;
    background: none;
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    border: none;
    color: #1D1B23;
    cursor: pointer;
}

.Commodities:hover {
    background-color: rgb(201, 201, 204);
    border-radius: 54px;
}

.crypto {
    padding: 10px 25px;
    background: none;
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    border: none;
    color: #1D1B23;
    cursor: pointer;


}

.crypto:hover {
    background-color: rgb(201, 201, 204);
    border-radius: 54px;
}



.Searchbar_comp {
    background-color: #ffffff;
    width: 309px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 42px;
    padding: 10px;
    gap: 10px;
}


.search_bar {
    width: 204px;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #1D1B23;
}

.Searchbar_comp img {
    cursor: pointer;
}

.Calenderbar {
    background-color: #ffffff;
    width: 309px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 42px;
    padding: 10px;
    gap: 10px;
}

.calendertext h1 {
    font-size: 16px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #1D1B23;
}

.calendertext p {
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #1D1B23;
}



/* table header section end  */



.bottom_screen {
    height: 671px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    margin-top: 10px;
    border-radius: 20px;

}



.profile_table {
    width: 100%;
    border-collapse: collapse;
}

.profile_thead {
    border-bottom: 2px solid #E9ECFF;
    height: 70px;
    width: 100%;

}



.profile_thead tr {
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: #222224;

}

.profile_thead tr img {
    height: 12px;
    width: 12px;
    margin-left: 5px;
}

.profile_shares {
    display: flex;
    height: 70px;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #000000;
}

.profilevolume {
    font-size: 14px;
    font-family: "Poppins", sans-serif;

}

.profilechange {
    font-size: 14px;
    font-family: "Poppins", sans-serif;

}

.profile_lastprice {
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #000000;
}

.graphimg img {
    width: 180px;
}


.circle_table {
    background-color: #FFAB2D;
    border-radius: 50%;
    border: none;
    height: 33px;
    width: 33px;
    color: white;

}


.rankcircle button {
    background-color: #EFEFEF;
    border-radius: 50%;
    text-align: center;
    border: none;
    height: 43px;
    width: 43px;
    font-size: 14px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #000000;
}

.profile_tbody tr {
    width: 100%;
    height: 70px;
}

.profile_buy_sell {
    border: 2px solid black;
    padding: 8px 20px;
    text-transform: uppercase;
    border-radius: 50px;
    background: white;
    font-size: 14px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    color: #000000;
    cursor: pointer;
}

.profile_buy_sell:hover {
    background-color: rgb(239, 239, 240);
}

.profile_sell {
    margin-left: 10px;
}

.profilemore {
    height: 20px;

}

.profile_table th,
.profile_table td {
    padding-left: 20px;
    text-align: left;
}


/* nextpage menu section */

.profile_nextpage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: 100%;
    /* background-color: #eee0e0; */
    margin-top: 20px;
    margin-bottom: 10px;

}

.profile_nextpage_btn {
    margin-right: 30px;
    display: flex;
    gap: 15px;

}



.profile_nextpage_btn_btn {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    border: none;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #3E4954;
}

.profile_nextpage_btn_black_btn {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    border: none;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
}

.tablemore {
    height: 40px;
    width: 40px;
    border-radius: 12px;
    background-color: #FFFFFF;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.tablemore:hover {
    background-color: rgb(201, 195, 195);
}

.profile_nextpage_btn_btn:hover {
    background-color: rgb(201, 195, 195);
}


.profile_nextpage div p {
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: #3E4954;
}






@media only screen and (max-width:1550px) {
    .Head_screen {
        gap: 60px;
        height: 44px;
    }

    .Head_screen_button {
        width: 254px;
        padding: 8px;

    }

    .shares {
        padding: 5px 10px;
        font-size: 12px;
    }

    .Commodities {
        padding: 5px 10px;
        font-size: 12px;
    }

    .crypto {
        padding: 5px 10px;
        font-size: 12px;

    }


    .Searchbar_comp {
        width: 209px;
        padding: 10px;
    }

    .search_bar {
        width: 174px;
        font-size: 12px;
    }

    .Searchbar_comp img {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }

    .Calenderbar {
        width: 190px;
        padding: 10px;
        gap: 5px;
    }

    .calendertext h1 {
        font-size: 14px;
    }

    .calendertext p {
        font-size: 8px;
    }

    .calenderimg {
        width: 26px;
        height: 20px;
    }

    .calenderarrowimg {
        width: 26px;
        height: 20px;
    }


    /* table header section end  */



    .bottom_screen {
        height: 671px;
    }



    .profile_thead {
        height: 40px;

    }



    .profile_thead tr {
        font-size: 12px;

    }

    .profile_thead tr img {
        height: 7px;
        width: 7px;
        margin-left: 2px;
    }


    .profile_shares {
        height: 50px;
        gap: 10px;
        font-size: 12px;
    }

    .profile_lastprice {
        font-size: 12px;

    }

    .graphimg img {
        width: 100px;
    }

    .circle_table {
        height: 23px;
        width: 23px;
    }


    .rankcircle button {
        height: 33px;
        width: 33px;
        font-size: 12px;
    }

    .profile_tbody tr {
        width: 100%;
        height: 50px;
        cursor: pointer; /* Change cursor style to pointer */
        transition: background-color 0.3s;
    }
    .profile_tbody tr:hover {
        background-color: #f0f0f0; /* Change background color on hover */
    }

    .profile_buy_sell {
        padding: 4px 10px;
        font-size: 12px;

    }


    .profilemore {
        height: 15px;

    }

    .profilevolume {
        font-size: 12px;


    }

    .profilechange {
        font-size: 12px;


    }


    .profile_nextpage_btn {
        margin-right: 30px;
        display: flex;
        gap: 15px;

    }


    .profile_nextpage_btn_btn img {
        height: 20px;
    }

    .profile_nextpage_btn_btn {
        height: 30px;
        width: 30px;
        font-size: 12px;

    }

    .profile_nextpage_btn_black_btn {
        height: 30px;
        width: 30px;
        font-size: 12px;
    }

    .tablemore {
        height: 30px;
        width: 30px;
    }



    .profile_nextpage div p {
        font-size: 12px;

    }




}











/* responsive 1050 */







@media only screen and (max-width:1050px) {
    .Head_screen {
        gap: 10px;
        height: 34px;
    }

    .Head_screen_button {
        width: 200px;
        padding: 4px;

    }

    .shares {
        padding: 5px 10px;
        font-size: 10px;
    }

    .Commodities {
        padding: 5px 10px;
        font-size: 10px;
    }

    .crypto {
        padding: 5px 10px;
        font-size: 10px;

    }


    .Searchbar_comp {
        width: 180px;
        padding: 10px;
    }

    .search_bar {
        width: 174px;
        font-size: 12px;
    }

    .Searchbar_comp img {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }

    .Calenderbar {
        width: 160px;
        padding: 10px;
        gap: 5px;
    }

    .calendertext h1 {
        font-size: 13px;
    }

    .calendertext p {
        font-size: 8px;
    }

    .calenderimg {
        width: 16px;
        height: 60px;
    }

    .calenderarrowimg {
        width: 26px;
        height: 20px;
    }


    /* table header section end  */



    .bottom_screen {
        height: 671px;
    }



    .profile_thead {
        height: 30px;

    }



    .profile_thead tr {
        font-size: 10px;

    }

    .profile_thead tr img {
        height: 5 px;
        width: 5px;
        margin-left: 6px;
    }


    .profile_shares {
        height: 50px;
        gap: 3px;
        font-size: 8px;
    }

    .profile_lastprice {
        font-size: 8px;

    }

    .graphimg img {
        width: 60px;
    }

    .circle_table {
        height: 18px;
        width: 18px;
    }

    .circle_table span {
        font-size: 10px;
    }


    .rankcircle button {
        height: 20px;
        width: 20px;
        font-size: 10px;
    }

    .profile_tbody tr {
        width: 100%;
        height: 50px;
    }


    .profile_buy_sell {
        padding: 3px 4px;
        font-size: 8px;

    }

    .profile_buy_sell img{
        height: 7px;
    }


    .profilemore {
        height: 13px;
    }

    .profilemore {
        height: 15px;

    }

    .profilevolume {
        font-size: 10px;


    }

    .profilechange {
        font-size: 10px;


    }



}


.main-container {
  overflow: hidden;
}

.main-container,
.main-container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 12.326px;
  position: relative;
  width: 106px;
  margin: 0 auto;
  opacity: 0.5;
  /* border-radius: 0 0 36.977px 36.977px; */
}
.frame-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 106px;
  padding: 9.86px 9.86px 9.86px 9.86px;
  background: #f3f3f3;
  border-radius: 6.163px;
}
.sl {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 19px;
  color: #333333;
  font-family: Poppins, var(--default-font-family);
  font-size: 12.760601043701172px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  white-space: nowrap;
  z-index: 1;
}
.multiple {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 19px;
  color: #333333;
  font-family: Poppins, var(--default-font-family);
  font-size: 12.760601043701172px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  white-space: nowrap;
  z-index: 2;
}




